import filters from '@filters/filters';
import { isEmpty, dateFilter } from '@utils/utils';
import { sexMap } from '@/dictionaries/appointment';
export default {
  name: 'TodoListItem',
  mixins: [filters],
  components: {},
  data() {
    return {
      sexMap
    };
  },
  props: {
    detailInfo: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    type: {
      type: [String, Number],
      default: '1'
    }
  },
  computed: {},
  methods: {
    // 日期格式化
    dateFilter,
    // 空字段过滤
    fieldEmptyFilter(data, returnEmpty = '无') {
      return !isEmpty(data) ? data : returnEmpty;
    },
    goDetail() {
      this.$emit('go-detail', this.detailInfo);
    },
    async initData() {}
  },
  filters: {},
  watch: {},
  created() {
    this.initData();
  },
  mounted() {}
};