var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-card cyui-todo-item",
    on: {
      "click": _vm.goDetail
    }
  }, [_c('div', {
    staticClass: "cyui-card-header"
  }, [_c('div', {
    staticClass: "cyui-card-header-line cyui-hairline-bottom"
  }, [_c('div', {
    staticClass: "cyui-card-header-content"
  }, [_c('div', {
    staticClass: "cyui-flexbox"
  }, [_c('div', {
    staticClass: "cyui-todo-header-icon"
  }, [_c('svg-icon', {
    attrs: {
      "icon-class": `home_todo_${_vm.type}`
    }
  })], 1), _vm.type === '1' ? _c('div', {
    staticClass: "cyui-flexbox-item cyui-ellipsis"
  }, [_vm._v("预约记录")]) : _vm.type === '2' ? _c('div', {
    staticClass: "cyui-flexbox-item cyui-ellipsis"
  }, [_vm._v("保洁记录")]) : _vm.type === '3' ? _c('div', {
    staticClass: "cyui-flexbox-item cyui-ellipsis"
  }, [_vm._v("维修记录")]) : _vm.type === '4' ? _c('div', {
    staticClass: "cyui-flexbox-item cyui-ellipsis"
  }, [_vm._v("定制记录")]) : _vm.type === '5' ? _c('div', {
    staticClass: "cyui-flexbox-item cyui-ellipsis"
  }, [_vm._v("退房记录")]) : _vm.type === '6' ? _c('div', {
    staticClass: "cyui-flexbox-item cyui-ellipsis"
  }, [_vm._v("报修记录")]) : _vm._e()])]), _vm.type === '1' ? _c('div', {
    staticClass: "cyui-card-header-extra"
  }, [_vm._v("预约时间：" + _vm._s(_vm.detailInfo.bookVisitTime))]) : _vm._e(), _vm.type === '3' || _vm.type === '4' ? _c('div', {
    staticClass: "cyui-card-header-extra"
  }, [_vm._v("预约时间：" + _vm._s(_vm.dateFilter(_vm.detailInfo.visitingTime)))]) : _vm._e(), _vm.type === '6' || _vm.type === '2' ? _c('div', {
    staticClass: "cyui-card-header-extra"
  }, [_vm._v("创建时间：" + _vm._s(_vm.dateFilter(_vm.detailInfo.createTime)))]) : _vm._e()])]), _c('div', {
    staticClass: "cyui-card-body"
  }, [_vm.type === '1' ? _c('div', {
    staticClass: "cyui-todo-info"
  }, [_c('div', [_vm._v("姓名：" + _vm._s(_vm.detailInfo.bookerName || '-'))]), _c('div', [_vm._v("性别：" + _vm._s(_vm.detailInfo.bookerSex || '未知'))]), _c('div', [_vm._v("联系方式：" + _vm._s(_vm.detailInfo.bookerPhone || '-'))]), _c('div', [_vm._v("意向户型：" + _vm._s(_vm.detailInfo.expectLayouts || '-'))])]) : _vm._e(), _vm.type === '2' ? _c('div', {
    staticClass: "cyui-todo-info"
  }, [_c('div', [_vm._v("房号：" + _vm._s(_vm.detailInfo.spaceName || '-'))]), _c('div', [_vm._v("姓名：" + _vm._s(_vm.detailInfo.contactsName || '-'))]), _c('div', [_vm._v("联系方式：" + _vm._s(_vm.detailInfo.contactsMobile || '-'))]), _c('div', [_vm._v("保洁类型：" + _vm._s(_vm.detailInfo.questionCategoryName || '-'))]), _c('div', [_vm._v("期望上门时间：" + _vm._s(_vm.detailInfo.expectRepairDate || '-'))])]) : _vm._e(), _vm.type === '3' || _vm.type === '4' ? _c('div', {
    staticClass: "cyui-todo-info"
  }, [_c('div', [_vm._v("房号：" + _vm._s(_vm.detailInfo.houseName || '-'))]), _c('div', [_vm._v("姓名：" + _vm._s(_vm.detailInfo.contacts || '-'))]), _c('div', [_vm._v("联系方式：" + _vm._s(_vm.detailInfo.phone || '-'))]), _vm.type === '2' ? _c('div', [_vm._v("保洁类型：" + _vm._s(_vm.detailInfo.typeName || '-'))]) : _vm.type === '3' ? _c('div', [_vm._v("维修类型：" + _vm._s(_vm.detailInfo.typeName || '-'))]) : _vm.type === '4' ? _c('div', [_vm._v("服务类型：" + _vm._s(_vm.detailInfo.typeName || '-'))]) : _vm._e(), _c('div', [_vm._v("提交时间：" + _vm._s(_vm.dateFilter(_vm.detailInfo.createTime)))])]) : _vm._e(), _vm.type === '5' ? _c('div', {
    staticClass: "cyui-todo-info"
  }, [_c('div', [_vm._v("房号：" + _vm._s(_vm.detailInfo.roomNo || '-'))]), _c('div', [_vm._v("姓名：" + _vm._s(_vm.detailInfo.customerName || '-'))]), _c('div', [_vm._v("联系方式：" + _vm._s(_vm.detailInfo.customerPhone || '-'))]), _c('div', [_vm._v("上门验房时间：" + _vm._s(_vm.detailInfo.checkTime || '-'))]), _c('div', [_vm._v("创建时间：" + _vm._s(_vm.detailInfo.userCreateTime || '-'))])]) : _vm._e(), _vm.type === '6' ? _c('div', {
    staticClass: "cyui-todo-info"
  }, [_c('div', [_vm._v("房号：" + _vm._s(_vm.detailInfo.spaceName || '-'))]), _c('div', [_vm._v("姓名：" + _vm._s(_vm.detailInfo.contactsName || '-'))]), _c('div', [_vm._v("联系方式：" + _vm._s(_vm.detailInfo.contactsMobile || '-'))]), _c('div', [_vm._v("问题类型：" + _vm._s(_vm.detailInfo.questionCategoryName || '-'))]), _c('div', [_vm._v("期望上门时间：" + _vm._s(_vm.detailInfo.expectRepairDate || '-'))])]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };