var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('van-popup', {
    style: {
      height: '100%'
    },
    attrs: {
      "position": "bottom",
      "lock-scroll": false,
      "lazy-render": true,
      "get-container": _vm.getContainer
    },
    model: {
      value: _vm.showPopup,
      callback: function ($$v) {
        _vm.showPopup = $$v;
      },
      expression: "showPopup"
    }
  }, [_c('cyui-layout', {
    staticStyle: {
      "background-color": "#f8f8f8"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": `请选择${_vm.title}`
          },
          on: {
            "go-back": function ($event) {
              _vm.showPopup = !_vm.showPopup;
            }
          },
          scopedSlots: _vm._u([{
            key: "headerRight",
            fn: function () {
              return [_c('div')];
            },
            proxy: true
          }], null, false, 378232108)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-scroller-panel"
        }, [_c('cyui-scroller', {
          ref: "scroller",
          staticClass: "wrapper",
          attrs: {
            "scrollbar": _vm.scrollbarConfig,
            "pullDownRefresh": _vm.pullDownRefreshConfig,
            "pullUpLoad": _vm.pullUpLoadConfig,
            "startY": parseInt(_vm.startY)
          },
          on: {
            "pullingDown": _vm.handlePullingDown,
            "pullingUp": _vm.handlePullingUp
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_vm.listData.length > 0 ? _c('div', {
                staticClass: "cyui-project-list"
              }, [_c('van-cell-group', _vm._l(_vm.listData, function (item) {
                return _c('van-cell', {
                  key: `search${item.id}`,
                  on: {
                    "click": function ($event) {
                      return _vm.handleSelectProject(item);
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "icon",
                    fn: function () {
                      return [_vm.type === '1' && _vm.from ? [_c('div', {
                        staticClass: "cyui-project-list-thumb"
                      }, [_c('svg-icon', {
                        attrs: {
                          "icon-class": item.isSelected ? 'radio_checked' : 'radio'
                        }
                      })], 1)] : _vm.type === '2' && _vm.from ? [_c('div', {
                        staticClass: "cyui-project-list-thumb"
                      }, [_c('svg-icon', {
                        attrs: {
                          "icon-class": item.isSelected ? 'checkbox_checked' : 'checkbox'
                        }
                      })], 1)] : _vm._e()];
                    },
                    proxy: true
                  }, {
                    key: "title",
                    fn: function () {
                      return [_c('div', {
                        staticClass: "cyui-flexbox cyui-project-info"
                      }, [_c('div', {
                        staticClass: "cyui-flexbox-item"
                      }, [_c('div', {
                        staticClass: "cyui-project-name"
                      }, [_vm._v(_vm._s(item.label))]), _c('div', {
                        staticClass: "cyui-project-job"
                      }, [_vm._v(_vm._s(`${item.projectName || '-'}`) + " ")])])])];
                    },
                    proxy: true
                  }, {
                    key: "right-icon",
                    fn: function () {
                      return undefined;
                    },
                    proxy: true
                  }], null, true)
                });
              }), 1)], 1) : _vm._e()];
            },
            proxy: true
          }])
        })], 1), _vm.listData.length === 0 && _vm.isLoading === false ? _c('no-data', {
          attrs: {
            "msg": _vm.noDataMessage
          },
          scopedSlots: _vm._u([{
            key: "btn",
            fn: function () {
              return [_vm.isRefreshPage ? _c('div', {
                staticClass: "cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius",
                on: {
                  "click": _vm.handleRefreshPage
                }
              }, [_vm._v(" 刷新重试 ")]) : _vm._e()];
            },
            proxy: true
          }], null, false, 3275925837)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-actionbar"
        }, [_c('div', {
          staticClass: "cyui-actionbar-item cyui-actionbar-btns"
        }, [_c('button', {
          staticClass: "cyui-btn cyui-btn-ghost cyui-btn-block",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              _vm.showPopup = !_vm.showPopup;
            }
          }
        }, [_vm._v(" 取消 ")]), _c('button', {
          staticClass: "cyui-btn cyui-btn-primary cyui-btn-block",
          attrs: {
            "type": "button"
          },
          on: {
            "click": _vm.handleConfirmSelect
          }
        }, [_vm._v(" 确定 ")])])])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };