import request from '@utils/request';
// import qs from 'qs'

/**
 * Maintain-获取列表数据
 * url:'/service/getRepairList'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   pageSize: '' //  分页大小
 *   pageNum: '' //  当前页数
 *   projectId: '' //  项目id
 *   status: '' //  进度 0:已创建 1:已受理 2:处理中 3:已完成  全部则不传
 * }
 * @returns {*}
 */
export const getListAPI = (params = {}) => {
  return request.get('/service/getRepairList', {
    params
  });
};

/**
 * Maintain-获取详情数据
 * url:'/service/getRepairDetail'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   id: '' //  ID
 * }
 * @returns {*}
 */
export const getDetailAPI = (params = {}) => {
  return request.get('/service/getRepairDetail', {
    params
  });
};