import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import common from '@mixins/common';
import scrollList from '@mixins/scrollList';
import getPageList from '@mixins/getPageList';
import filters from '@filters/filters';
import { TodoListItem, ProjectSelect } from './components';
import { menuListData, categoryListData } from '@dictionaries/home';
import { getBookListAPI as getAppointmentListAPI } from '@api/appointment';
import { getListAPI as getCleanListAPI } from '@api/clean';
import { getListAPI as getMaintainListAPI } from '@api/maintain';
import { getListAPI as getCustomListAPI } from '@api/custom';
import { getListAPI as getMaintenanceListAPI } from '@api/maintenance';
import { returnLogin } from '@utils/common';
import { getCheckOutListAPI } from '@api/checkOut';
export default {
  name: 'HomeIndex',
  mixins: [common, filters, scrollList, getPageList],
  components: {
    TodoListItem,
    ProjectSelect
  },
  data() {
    return {
      headerTitle: '首页',
      menuInfo: {
        // 菜单数据
        value: '1',
        //
        listData: menuListData
      },
      categoryInfo: {
        // 分类数据
        value: '1',
        //
        listData: categoryListData
      },
      initParams: {
        // 初始化参数
        page: 1,
        size: 3
      },
      searchKey: {},
      // 搜索参数
      projectPopupInfo: {
        // 项目选择
        title: '项目',
        showPopup: false,
        // 是否显示项目弹出层
        value: [],
        label: [],
        cacheData: [],
        // 选中的缓存数据
        selectedListData: [] // 选中的列表数据
      },

      listTotalCount: 0
    };
  },
  props: {
    status: {
      type: [Number, String],
      default: '1'
    }
  },
  computed: {
    ...mapGetters('user', ['projectInfo', 'userInfo'])
  },
  methods: {
    exit() {
      returnLogin({});
    },
    // 进入模块
    goModule(data = {}) {
      let {
        routeName
      } = data;
      console.log(routeName, 'abc');
      this.$router.push({
        name: routeName
      });
    },
    // 进入详情
    goModuleDetail(data) {
      let {
        id
      } = data;
      let {
        status
      } = this;
      let routeName = '';
      switch (status) {
        case '1':
          routeName = 'AppointmentDetail';
          break;
        case '2':
          routeName = 'CleanDetail';
          break;
        case '3':
          routeName = 'MaintainDetail';
          break;
        case '4':
          routeName = 'CustomDetail';
          break;
        case '5':
          routeName = 'CheckOutDetail';
          break;
        case '6':
          routeName = 'maintenanceDetail';
          break;
      }
      this.$router.push({
        name: routeName,
        query: {
          id
        }
      });
    },
    // 进入待办列表
    goModuleList() {
      let {
        status
      } = this;
      let routeName = '';
      let moduleStatus = '';
      switch (status) {
        case '1':
          routeName = 'AppointmentList';
          moduleStatus = '0';
          break;
        case '2':
          routeName = 'CleanList';
          moduleStatus = '2';
          break;
        case '3':
          routeName = 'MaintainList';
          moduleStatus = '';
          break;
        case '4':
          routeName = 'CustomList';
          moduleStatus = '2';
          break;
        case '5':
          routeName = 'CheckOutList';
          moduleStatus = '';
          break;
      }
      this.$router.push({
        name: routeName,
        query: {
          status: moduleStatus
        }
      });
    },
    handleTabChange({
      name
    }) {
      if (name !== this.status) {
        this.$router.replace({
          name: 'HomeIndex',
          query: {
            status: name
          }
        });
      }
    },
    handleConformSelectProject(data = {}) {
      // 选择项目操作
      let {
        cacheData,
        listData
      } = data;
      this.projectPopupInfo.cacheData = cacheData;
      this.projectPopupInfo.selectedListData = listData;
      let projectInfo = listData[0];
      this.$store.dispatch('user/SetProjectInfo', {
        projectId: projectInfo.id,
        cityName: projectInfo.label,
        projectName: projectInfo.projectName,
        dcProjectId: projectInfo.dcProjectId,
        isHospital: projectInfo.isHospital
      });
      this.setIconVisibility(projectInfo);
      this.initData();
    },
    async getListData({
      isPullDown,
      isShowLoading
    } = {
      isPullDown: false,
      isShowLoading: true
    }) {
      // 获取列表数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          status,
          listData
        } = this;
        let {
          projectId,
          dcProjectId
        } = this.projectInfo;
        let {
          page,
          size
        } = this.searchKey;
        let response;
        switch (status) {
          case '1':
            response = await getAppointmentListAPI({
              pageNum: page,
              pageSize: size,
              projectId,
              state: '0' // 待办
            });

            break;
          case '2':
            response = await getMaintenanceListAPI({
              pageNum: page,
              pageSize: size,
              projectId: dcProjectId,
              type: 2,
              status: -1
            });
            break;
          case '3':
            response = await getMaintainListAPI({
              pageNum: page,
              pageSize: size,
              projectId,
              status: '0,1,2' // 待办
            });

            break;
          case '4':
            response = await getCustomListAPI({
              pageNum: page,
              pageSize: size,
              projectId,
              status: '2' // 待办
            });

            break;
          case '5':
            response = await getCheckOutListAPI({
              pageNum: page,
              pageSize: size,
              projectId,
              status: '' // 全部
            });

            break;
          case '6':
            response = await getMaintenanceListAPI({
              pageNum: page,
              pageSize: size,
              projectId: dcProjectId,
              type: 1,
              status: -1 // 全部
            });

            break;
        }
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '10000' || `${result.code}` == 200) {
          // 请求成功
          let resultData = result.data || [];
          // let hasMore = resultData.length > 0
          // let hasMore = page < result.pages
          let hasMore = false;
          this.listData = !isPullDown ? [...listData, ...resultData] : [...resultData];
          this.listTotalCount = result.count;
          if (!hasMore) {
            this.isLastPage = true;
            // this.$toast('没有更多数据了！')
          }

          if (status === '1') {
            await this.$store.dispatch('appointment/SetAppointmentList', this.listData);
          }
          if (status === '5') {
            await this.$store.dispatch('checkOut/SetCheckOutList', this.listData);
          }
          await this.handleCallback({
            isError: false,
            hasMore
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async initProjectPopupInfo() {
      let {
        projectInfo
      } = this;
      let listData = [{
        id: projectInfo.projectId,
        parentId: '',
        label: projectInfo.cityName,
        projectName: projectInfo.projectName,
        hasChildren: false,
        children: [],
        isSelected: true,
        dcProjectId: projectInfo.dcProjectId,
        isHospital: projectInfo.isHospital
      }];
      this.projectPopupInfo.cacheData = [{
        from: 'home',
        listData
      }];
      this.projectPopupInfo.selectedListData = listData;
      this.setIconVisibility(projectInfo);
    },
    setIconVisibility(projectInfo) {
      let hosIcon = this.menuInfo.listData.find(i => i.id == '13');
      if (hosIcon) {
        hosIcon.isShow = projectInfo.isHospital ? true : false;
      }
    },
    async initData() {
      await this.resetParams();
      await this.getListData();
    }
  },
  filters: {},
  watch: {
    initParams: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.searchKey)) {
          this.searchKey = JSON.parse(JSON.stringify(newVal));
        }
      },
      deep: true,
      immediate: true
    },
    status: {
      handler: function (newVal, oldVal) {
        this.searchKey = JSON.parse(JSON.stringify(this.initParams));
        this.categoryInfo.value = newVal;
        this.initProjectPopupInfo();
        this.initData();
      },
      immediate: true
    }
  },
  created() {},
  mounted() {},
  beforeRouteEnter(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  },
  beforeRouteUpdate(to, from, next) {
    // console.log(to)
    // console.log(from)
    next();
  }
};