import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    UpdateRadioList({
      data,
      from,
      cacheData = []
    }) {
      // 更新单选数据-根据来源判断
      let copyRadioList = JSON.parse(JSON.stringify(cacheData));
      let fromData = copyRadioList.find(item => item.from === from); // 判断来源数据是否有缓存
      if (fromData) {
        copyRadioList = copyRadioList.map(item => {
          if (item.from === from) {
            let targetData = item.listData.find(target => target.id === data.id);
            if (!targetData) {
              item.listData = [data];
            } else {
              item.listData = item.listData.filter(target => target.id !== data.id);
            }
          }
          return item;
        });
      } else {
        copyRadioList.push({
          from,
          listData: [data]
        });
      }
      return new Promise((resolve, reject) => {
        resolve(copyRadioList);
      });
    },
    GetRadioList({
      from,
      cacheData = []
    }) {
      // 获取单选数据-根据来源判断
      let copyRadioList = JSON.parse(JSON.stringify(cacheData));
      return new Promise((resolve, reject) => {
        let fromData = copyRadioList.find(item => item.from === from); // 判断来源数据是否有缓存
        let response = fromData ? fromData.listData : [];
        resolve(response);
      });
    },
    ClearRadioList({
      from,
      cacheData = []
    }) {
      // 清空单选数据
      let copyRadioList = JSON.parse(JSON.stringify(cacheData));
      copyRadioList = copyRadioList.filter(item => item.from !== from);
      return new Promise((resolve, reject) => {
        resolve(copyRadioList);
      });
    },
    DeleteRadioList({
      data,
      from,
      cacheData = []
    }) {
      // 删除单选数据
      let copyRadioList = JSON.parse(JSON.stringify(cacheData));
      copyRadioList = copyRadioList.map(item => {
        if (item.from === from) item.listData = item.listData.filter(target => target.id !== data.id);
        return item;
      });
      return new Promise((resolve, reject) => {
        resolve(copyRadioList);
      });
    },
    UpdateCheckboxList({
      data,
      from,
      cacheData = []
    }) {
      // 更新多选数据-判断来源
      let copyCheckboxList = JSON.parse(JSON.stringify(cacheData));
      let fromData = copyCheckboxList.find(item => item.from === from); // 判断来源数据是否有缓存
      if (fromData) {
        copyCheckboxList = copyCheckboxList.map(item => {
          if (item.from === from) {
            let targetData = item.listData.find(target => target.id === data.id);
            if (!targetData) {
              item.listData = [...item.listData, ...[data]];
            } else {
              item.listData = item.listData.filter(target => target.id !== data.id);
            }
          }
          return item;
        });
      } else {
        copyCheckboxList.push({
          from,
          listData: [data]
        });
      }
      return new Promise((resolve, reject) => {
        resolve(copyCheckboxList);
      });
    },
    GetCheckboxList({
      from,
      cacheData = []
    }) {
      // 获取多选数据-根据来源判断
      let copyCheckboxList = JSON.parse(JSON.stringify(cacheData));
      return new Promise((resolve, reject) => {
        let fromData = copyCheckboxList.find(item => item.from === from); // 判断来源数据是否有缓存
        let response = fromData ? fromData.listData : [];
        resolve(response);
      });
    },
    ClearCheckboxList({
      from,
      cacheData = []
    }) {
      // 清空多选数据
      let copyCheckboxList = JSON.parse(JSON.stringify(cacheData));
      copyCheckboxList = copyCheckboxList.filter(item => item.from !== from);
      return new Promise((resolve, reject) => {
        resolve(copyCheckboxList);
      });
    },
    DeleteCheckboxList({
      data,
      from,
      cacheData = []
    }) {
      // 删除多选数据
      let copyCheckboxList = JSON.parse(JSON.stringify(cacheData));
      copyCheckboxList = copyCheckboxList.map(item => {
        if (item.from === from && item.listData.length) {
          item.listData = item.listData.filter(target => target.id !== data.id);
        }
        return item;
      });
      return new Promise((resolve, reject) => {
        resolve(copyCheckboxList);
      });
    }
  }
};