import request from '@utils/request'
import { errorCallback } from '@utils/errorCallback'
import { returnErrorPage, returnLogin } from '@utils/common'
import instance from '@utils/request'
import { exp } from 'mathjs'
// import qs from 'qs'

/**
 * CheckOut-获取列表数据
 * url:'/checkOut/getList'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   pageSize: '' //  分页大小
 *   pageNum: '' //  当前页数
 *   projectId: '' //  项目id
 *   status: '' //  进度 2:进行中 3:已完成 全部则不传
 * }
 * @returns {*}
 */
export const getCheckOutListAPI = (params = {}) => {
  return request.get('/checkout/userNameList', { params })
}

/**
 * CheckOut-获取详情数据
 * url:'/checkOut/detail'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   projectId: '' // 项目ID
 *   roomNo: '' // 房号
 * }
 * @returns {*}
 */
export const getDetailAPI = (params = {}) => {
  return request.get('/checkout/checkOutDetail', { params })
}

/**
 * CheckOutCheck-管家确认水电金额线上
 * url:'/checkout/waterElectricityConfirm'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   contractId: '' //  合同ID
 *   waterElectricity: ''  //  水电的金额
 * }
 * @returns {*}
 */
export const submitCheckOutCheckAPI = (params = {}) => {
  return request.post('/checkout/waterElectricityConfirm', params)
}

/**
 * CheckOutCheck-管家确认水电金额线下
 * url:'/checkout/waterElectricityConfirm'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   contractId: '' //  合同ID
 *   waterElectricity: ''  //  水电的金额
 * }
 * @returns {*}
 */
export const submitCheckOutCheckOffAPI = (params = {}) => {
  return request.post('/checkout/waterElectricityConfirmOffLine', params)
}

/**
 * History-获取房源信息
 * url:'/checkOut/history'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   projectId: '' // 项目ID
 *   roomNo: '' // 房号
 * }
 * @returns {*}
 */
export const getHistoryAPI = (params = {}) => {
  return request.get('/checkout/history', { params })
}

/**
 * managerCheckOut-管家确认申请退房
 * url:'/checkout/managerCheckOut'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   personalReason, //个人退房原因
 *   houseReason,   //硬件退房原因
 *   serviceReason,   //服务退房原因
 *   otherReason,   //其他原因
 *   contractId,   //合同ID
 *   checkTime,   //验房时间
 *   leaveTime,   //离店时间
 *   roomNo,
 *   userName,
 *   mobile
 * }
 * @returns {*}
 */
export const submitCheckOutApplyAPI = (params = {}) => {
  return request.post('/checkout/managerCheckOut', params)
}

/**
 * 退房取消
 * url:'/checkOut/history'
 * method:POST
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   id: '' // 列表id
 * }
 * @returns {*}
 */
export const cancelCheckOut = (params = {}) => {
  return request.post('/checkout/cancelCheckOut', params)
}

/**
 * 退房原因列表
 * url:'/checkout/reason/getList'
 * method:GET
 * @Header Authorization: 登录接口返回的token
 * @Query params:{
 *   id: '' // 列表id
 * }
 * @returns {*}
 */
export const getCheckOutReasonApi = (params = {}) => {
  return request.get('/checkout/reason/getList', { params })
}

export const getHouseIotAbilityApi = (params = {}) => {
  return request.get('/checkout/houseIotAbility', { params })
}

export const getWaterElectricityApi = (params = {}) => {
  return request.get('/checkout/getWaterElectricity', { params })
}
