var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper",
    staticStyle: {
      "background-color": "#FFFFFF"
    }
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return undefined;
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-banner"
        }, [_c('div', {
          staticClass: "cyui-banner-img",
          staticStyle: {
            "position": "relative"
          }
        }, [_c('img', {
          attrs: {
            "src": require("../../assets/images/home/banner.png"),
            "alt": ""
          }
        }), _c('span', {
          staticClass: "cyui-exit",
          on: {
            "click": _vm.exit
          }
        }, [_vm._v(" 退出 "), _c('svg-icon', {
          attrs: {
            "icon-class": "exit"
          }
        })], 1)]), _c('div', {
          staticClass: "cyui-banner-title",
          on: {
            "click": function ($event) {
              _vm.projectPopupInfo.showPopup = true;
            }
          }
        }, [_c('div', {
          staticClass: "cyui-flexbox cyui-banner-title-text"
        }, [_c('div', [_vm._v(_vm._s(_vm.projectInfo.cityName))]), _c('div', {
          staticClass: "cyui-banner-title-arrow"
        }, [_c('img', {
          attrs: {
            "src": require("../../assets/images/home/img_003.png"),
            "alt": ""
          }
        })])]), _c('div', {
          staticClass: "cyui-banner-title-describe"
        }, [_vm._v(_vm._s(_vm.projectInfo.projectName))])]), _c('div', {
          staticClass: "cyui-menu-box"
        }, [_c('div', {
          staticClass: "cyui-flexbox cyui-flexbox-wrap cyui-menu"
        }, [_vm._l(_vm.menuInfo.listData, function (item, index) {
          return [item.isShow ? _c('div', {
            key: `menuInfo${index}`,
            staticClass: "cyui-menu-item",
            on: {
              "click": function ($event) {
                return _vm.goModule(item);
              }
            }
          }, [_c('div', {
            staticClass: "cyui-menu-icon",
            style: {
              backgroundColor: item.backgroundColor,
              width: "34px",
              height: "34px"
            }
          }, [item.size ? _c('svg-icon', {
            style: {
              width: item.size,
              height: item.size
            },
            attrs: {
              "icon-class": item.svgName
            }
          }) : _c('svg-icon', {
            style: {
              width: "20px",
              height: "20px"
            },
            attrs: {
              "icon-class": item.svgName
            }
          })], 1), _c('div', {
            staticClass: "cyui-menu-label"
          }, [_vm._v(_vm._s(item.name))])]) : _vm._e()];
        })], 2)])]), _c('van-sticky', [_c('div', {
          staticClass: "cyui-titlebar cyui-titlebar-default cyui-todo-title"
        }, [_c('div', {
          staticClass: "cyui-titlebar-title"
        }, [_vm._v(" 待办事项 ")]), _c('div', {
          staticClass: "cyui-titlebar-nav"
        })]), _c('div', {
          staticClass: "cyui-todo-navbar"
        }, [_c('cyui-navbar', {
          attrs: {
            "swipe-threshold": 2,
            "show-title-number": "",
            "list-data": _vm.categoryInfo.listData
          },
          on: {
            "change": _vm.handleTabChange
          },
          model: {
            value: _vm.categoryInfo.value,
            callback: function ($$v) {
              _vm.$set(_vm.categoryInfo, "value", $$v);
            },
            expression: "categoryInfo.value"
          }
        })], 1)]), _c('div', {
          staticClass: "cyui-todo-body"
        }, [_vm.listData.length ? [_c('div', {
          staticClass: "cyui-todo-list"
        }, _vm._l(_vm.listData, function (item, index) {
          return _c('todo-list-item', {
            key: index,
            attrs: {
              "detail-info": item,
              "type": _vm.status
            },
            on: {
              "go-detail": function ($event) {
                return _vm.goModuleDetail(item);
              }
            }
          });
        }), 1), _vm.listTotalCount > 3 ? _c('div', {
          staticClass: "cyui-todo-more",
          on: {
            "click": _vm.goModuleList
          }
        }, [_vm._v(" 查看更多 ")]) : _vm._e()] : _vm._e(), _vm.listData.length === 0 && _vm.isLoading === false ? _c('no-data', {
          attrs: {
            "msg": _vm.noDataMessage
          },
          scopedSlots: _vm._u([{
            key: "btn",
            fn: function () {
              return [_vm.isRefreshPage ? _c('div', {
                staticClass: "cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius",
                on: {
                  "click": _vm.handleRefreshPage
                }
              }, [_vm._v(" 刷新重试 ")]) : _vm._e()];
            },
            proxy: true
          }], null, false, 3275925837)
        }) : _vm._e()], 2)];
      },
      proxy: true
    }])
  }), _c('project-select', {
    attrs: {
      "show": _vm.projectPopupInfo.showPopup,
      "title": _vm.projectPopupInfo.title,
      "type": "1",
      "from": "home",
      "cache-data": _vm.projectPopupInfo.cacheData
    },
    on: {
      "update:show": function ($event) {
        return _vm.$set(_vm.projectPopupInfo, "showPopup", $event);
      },
      "on-confirm-select": _vm.handleConformSelectProject
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };