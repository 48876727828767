import store from '@/store';
// let epidemicShow = store.getters['user/projectInfo'].isHospital ? true : false;

/**
 * 菜单列表
 * @type {({backgroundColor: string, svgName: string, name: string, id: string, routeName: string}|{backgroundColor: string, svgName: string, name: string, id: string, routeName: string}|{backgroundColor: string, svgName: string, name: string, id: string, routeName: string}|{backgroundColor: string, svgName: string, name: string, id: string, routeName: string}|{backgroundColor: string, svgName: string, name: string, id: string, routeName: string})[]}
 */
export const menuListData = [{
  id: '1',
  name: '来访接待',
  svgName: 'home_appointment_new',
  backgroundColor: '#e4c477',
  routeName: 'AppointmentList',
  isShow: true
},
// {
//   id: '2',
//   name: '发起保洁',
//   svgName: 'baoxiu',
//   backgroundColor: '#afe290',
//   routeName: 'CleanSend',
//   isShow: true
// },
// {
//   id: '2',
//   name: '预定记录',
//   svgName: 'home_reserve',
//   backgroundColor: '#00cac5',
//   routeName: 'develop',
//   isShow: true
// },
// {
//   id: '3',
//   name: '礼品核销',
//   svgName: 'home_gift',
//   backgroundColor: '#c3c16f',
//   routeName: 'develop',
//   isShow: true
// },
{
  id: '4',
  name: '保洁记录',
  svgName: 'home_clean',
  backgroundColor: '#bd90d0',
  routeName: 'CleanList',
  isShow: true
}, {
  id: '5',
  name: '维修记录',
  svgName: 'home_maintain',
  backgroundColor: '#d59169',
  routeName: 'MaintainList',
  isShow: true
}, {
  id: '6',
  name: '定制记录',
  svgName: 'home_custom',
  backgroundColor: '#f39d8a',
  routeName: 'CustomList',
  isShow: true
}, {
  id: '7',
  name: '房源查看',
  svgName: 'home_house',
  backgroundColor: '#00b5c9',
  routeName: 'HouseList',
  isShow: true
},
/* {
  id: '8',
  name: '咨询记录',
  svgName: 'home_consult',
  backgroundColor: '#d59169',
  routeName: 'develop'
} */
{
  id: '9',
  name: '签约管理',
  svgName: 'home_sign_contract',
  backgroundColor: '#A0C8EA',
  routeName: 'SignContractList',
  isShow: true
}, {
  id: '10',
  name: '退房记录',
  svgName: 'home_check_out_list',
  backgroundColor: '#E4C477',
  size: "34px",
  routeName: 'CheckOutList',
  isShow: true
}, {
  id: '11',
  name: '代客退房',
  svgName: 'home_check_out_apply',
  backgroundColor: '#EAA1A0',
  size: "34px",
  routeName: 'CheckOutApply',
  isShow: true
}, {
  id: '12',
  name: '使用说明',
  svgName: 'shuoming',
  backgroundColor: '#5db2e0',
  size: "34px",
  routeName: 'Directions',
  isShow: true
},
// {
//   id: '13',
//   name: '防疫点房态图',
//   svgName: 'home_house_epidemic',
//   backgroundColor: '#EAA1A0',
//   routeName: 'HouseEpidemic',
//   isShow: epidemicShow
// }
{
  id: '14',
  name: '物资管理',
  svgName: 'wuzi',
  backgroundColor: 'transparent',
  size: "34px",
  routeName: 'Assets',
  isShow: true
}, {
  id: '15',
  name: '账单纠正',
  svgName: 'home_sign_contract',
  backgroundColor: '#A0C8EA',
  routeName: 'billCorrection',
  isShow: false //todo master临时隐藏
},
// {
//   id: '16',
//   name: '日报列表',
//   svgName: 'home_sign_contract',
//   backgroundColor: '#A0C8EA',
//   routeName: 'dailyReportList',
//   isShow: true
// }
{
  id: '17',
  name: '扫码核销',
  svgName: 'coupon_scan',
  backgroundColor: '#E4C477',
  size: "34px",
  routeName: 'CouponScan',
  isShow: true
}, {
  id: '18',
  name: '核销记录',
  svgName: 'coupon_list',
  backgroundColor: '#EAA1A0',
  size: "34px",
  routeName: 'CouponScanList',
  isShow: true
}, {
  id: '19',
  name: '换房管理',
  svgName: 'icon_room_change',
  backgroundColor: '#A0C8EA',
  routeName: 'roomChange',
  isShow: true
}];

/**
 * 分类列表数据
 * @type {({number: number, children: [], id: string, label: string}|{number: number, children: [], id: string, label: string}|{number: number, children: [], id: string, label: string}|{number: number, children: [], id: string, label: string})[]}
 */
export const categoryListData = [{
  id: '1',
  label: '预约记录',
  number: 0,
  children: []
}, {
  id: '2',
  label: '保洁记录',
  number: 0,
  children: []
},
// {
//   id: '3',
//   label: '维修记录',
//   number: 0,
//   children: []
// },
{
  id: '5',
  label: '退房记录',
  number: 0,
  children: []
}, {
  id: '4',
  label: '定制记录',
  number: 0,
  children: []
}, {
  id: '6',
  label: '报修记录',
  number: 0,
  children: []
}];