export { sexMap } from './common';

/**
 * 状态
 0 未完成
 1 已完成
 * @type {{}}
 */
export const statusMap = {
  '0': {
    colorType: 'orange',
    text: '未完成'
  },
  '1': {
    colorType: 'gray',
    text: '已完成'
  }
};