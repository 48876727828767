import _debounce from "lodash/debounce";
import { mapGetters } from 'vuex';
import common from '@mixins/common';
import scrollList from '@mixins/scrollList';
import getPageList from '@mixins/getPageList';
import selectActionMixins from '@mixins/selectAction';
import filters from '@filters/filters';
// import { getProjectListDataAPI } from '@api/shouwen_approve'

export default {
  name: 'ProjectSelect',
  mixins: [common, filters, scrollList, getPageList, selectActionMixins],
  components: {},
  data() {
    return {
      searchKey: {
        // 查询参数
        page: 1,
        size: 20,
        projectName: ''
      },
      cartInfo: {
        // 购物车
        show: false,
        cacheData: [],
        // 缓存数据
        listData: [] // 列表数据
      },

      showPopup: false // 是否显示弹出层
    };
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      // 判断是否选择 1、单选 2、多选
      type: [Number, String],
      default: ''
    },
    from: {
      // 如果是选择 需要判断来源
      type: [Number, String],
      default: ''
    },
    cacheData: {
      // 缓存数据
      type: Array,
      default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: '请输入关键字'
    }
  },
  computed: {
    ...mapGetters('user', ['projectList'])
  },
  methods: {
    getContainer() {
      return document.querySelector('body');
    },
    async resetCartParams() {
      // 重置购物车参数
      let {
        cacheData
      } = this;
      this.cartInfo.show = false;
      this.cartInfo.listData = [];
      this.cartInfo.cacheData = cacheData; // 把人员列表页的缓存数据更新到搜索页面缓存中
    },

    updateListData() {
      // 更新列表数据
      let {
        listData,
        cartInfo
      } = this;
      if (cartInfo.listData.length > 0) {
        listData.map((item, index) => {
          let currentData = cartInfo.listData.find(dept => item.id === dept.id);
          this.$set(listData[index], 'isSelected', !!currentData);
        });
      } else {
        listData.map((item, index) => {
          this.$set(listData[index], 'isSelected', false);
        });
      }
    },
    handleConfirmSelect: _debounce(async function () {
      // 确认选择
      let {
        cacheData,
        listData
      } = this.cartInfo;
      this.$emit('on-confirm-select', {
        cacheData,
        listData
      });
      this.showPopup = false;
    }, 200),
    handleRefreshPage: _debounce(async function () {
      // 刷新页面
      await this.initData();
    }, 200),
    handleFilter: _debounce(async function () {
      // 查询，防抖
      await this.initData();
    }, 200),
    async handlePullingDown() {
      // 下拉刷新
      await this.initData({
        isPullDown: true,
        isShowLoading: false
      });
    },
    async handlePullingUp() {
      // 上拉加载
      this.searchKey.page++;
      await this.getListData({
        isShowLoading: false
      });
      await this.resetCartParams();
      await this.getProjectSelectedData();
    },
    async handleSelectProject(data) {
      // 选择项目
      let {
        type,
        from
      } = this;
      let {
        cacheData
      } = this.cartInfo;
      let response = [];
      if (type === '1' && from) {
        // 单选
        response = await this.UpdateRadioList({
          data,
          from,
          cacheData
        });
      } else if (type === '2' && from) {
        // 多选
        response = await this.UpdateCheckboxList({
          data,
          from,
          cacheData
        });
      }
      this.cartInfo.cacheData = response; // 保存缓存数据
    },

    async getProjectSelectedData() {
      // 获取选中的数据
      let {
        type,
        from
      } = this;
      let {
        cacheData
      } = this.cartInfo;
      let response = [];
      if (type === '1' && from) {
        // 单选
        response = await this.GetRadioList({
          from,
          cacheData
        });
      } else if (type === '2' && from) {
        // 多选
        response = await this.GetCheckboxList({
          from,
          cacheData
        });
      }
      this.cartInfo.listData = response;
      return Promise.resolve(response);
    },
    async getListData({
      isPullDown,
      isShowLoading
    } = {
      isPullDown: false,
      isShowLoading: true
    }) {
      // 获取列表数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          projectList,
          listData
        } = this;
        let resultData = projectList || [];
        resultData = resultData.map(item => {
          return {
            id: item.projectId,
            parentId: '',
            label: item.cityName,
            projectName: item.projectName,
            hasChildren: false,
            children: [],
            dcProjectId: item.dcProjectId,
            isHospital: item.isHospital
          };
        });
        let hasMore = false;
        this.listData = !isPullDown ? [...listData, ...resultData] : [...resultData];
        if (!hasMore) {
          this.isLastPage = true;
          // this.$toast('没有更多数据了！')
        }

        await this.handleCallback({
          isError: false,
          hasMore
        });
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    },
    async initData({
      isPullDown,
      isShowLoading
    } = {
      isPullDown: false,
      isShowLoading: true
    }) {
      await this.resetParams({
        isPullDown
      });
      // this.noDataMessage = '暂无数据哦～'
      await this.getListData({
        isPullDown,
        isShowLoading
      });
      await this.resetCartParams();
      await this.getProjectSelectedData();
      // this.updateListData()
    }
  },

  watch: {
    show: {
      handler: function (newVal, oldVal) {
        if (newVal !== this.showPopup) {
          this.showPopup = newVal;
        }
      },
      immediate: true
    },
    showPopup: {
      handler: async function (newVal, oldVal) {
        if (newVal) {
          this.searchKey.projectName = '';
          await this.initData();
        }
        this.$emit('update:show', newVal);
      }
    },
    'cartInfo.cacheData': {
      handler: function (newVal, oldVal) {
        // console.log(newVal)
        this.getProjectSelectedData();
      },
      deep: true
      // immediate: true
    },

    'cartInfo.listData': {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.updateListData();
        }
      },
      deep: true
      // immediate: true
    }
  },

  created() {},
  mounted() {
    // this.initData()
  }
};